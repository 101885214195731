import * as React from 'react';
import { SfProductEssentials } from '../classes/SfProductEssentials';
//import 'isomorphic-fetch';

// IA-4048
type EnumLiteralsOf<T extends object> = T[keyof T] 
export type ValuePackBundleType = EnumLiteralsOf<typeof ValuePackBundleType>
export const ValuePackBundleType = Object.freeze({
    None: 0 as 0,
    ValuePack: 1 as 1,
    Bundle: 2 as 2
});

// IA-4048 - Temporary CSS
const slideContainer: any = {
   //display: 'inline-block'
};

// IA-4048 - Temporary CSS
const slide: any = {
    //whiteSpace: 'nowrap'
};


interface FetchDataState {
    recentlyViewed: any | undefined;
    //forecasts: WeatherForecast[];
    loading: boolean;
}

//docs: https://facebook.github.io/react-native/docs/network

export class FetchData extends React.Component<any, any>{

    

    constructor(props: any) {
        super(props);
        this.state = {
            value: null,
            isLoading: true,
            recentlyViewed: []
        };
    }
    render() {

        let contents = this.state.loading
            ? <p><em>Loading...</em></p>
            : FetchData.renderContentsTable(this.state.recentlyViewed);
        if (!this.state.loading && this.state.recentlyViewed !== undefined && this.state.recentlyViewed !== null && this.state.recentlyViewed.length > 0) {
            return <div>
                {contents}
            </div>;
        }
        else {
            return "";
        }




        //return <h3>Hello from FetchData Component. Loading: {this.props.isLoading} !</h3>;
    }

    //{(window as any).stringResourcesRecentlyViewed.recentlyViewed}

    private static renderContentsTable(recentlyViewed: SfProductEssentials[]) {
        return <div className="recentlyViewed1">


                   <div className="panel panel--bordered">
                       <div className="panel__header">
                    <h2 className="panel__header-title panel__header-title--black">
                        {(window as any).stringResourcesRecentlyViewed.recentlyViewed}
                    </h2>
                       </div>
                       <div className="panel__body">
                            <div className="product-slider-controls">
                            <a className="product-slider-control product-slider-control--prev">
                            <img src="webcontent/icons/dropdown-arrow.svg" alt="Scroll left" />
                                </a>
                            <a className="product-slider-control product-slider-control--next">
                            <img src="webcontent/icons/dropdown-arrow.svg" alt="Scroll right" />
                                </a>
                            </div>
                           <div className="product-slider slider-rv">
                        {recentlyViewed.map(recentlyViewedProd =>
                            <div key={recentlyViewedProd.PRODUCT_ID} className="proudct-slider__slide">

                                <div className="product-card product-card--recently-viewed">
                                    <div className="product-card__image">
                                        <a target="_self" href={'product/detail/' + 
                                                                recentlyViewedProd.PRODUCT_ID + '/' +
                                                                recentlyViewedProd.URL_NAME}>

                                            {recentlyViewedProd.IsBogo &&
                                                <div id="divBogoImage">
                                                    <img className="product-card__badge"
                                                        src={recentlyViewedProd.BogoImage} />
                                                </div>
                                            }

                                            <img src={recentlyViewedProd.STANDARD_IMAGE} alt={recentlyViewedProd.SEO_IMAGE_ALT_TAG} />
                                        </a>
                                    </div>

                                    <div className="product-card__description">

                                        <a className="product-card__title"
                                            target="_self"
                                            href={'product/detail/' + recentlyViewedProd.PRODUCT_ID + '/' + recentlyViewedProd.URL_NAME + '/'}>
                                            {recentlyViewedProd.NAME}
                                        </a>

                                        <div className="product-card__rating">
                                            {recentlyViewedProd.TOTAL_REVIEWS > 0 ?
                                                <a target="_self"
                                                    href={'product/detail/' + recentlyViewedProd.PRODUCT_ID + '/' + recentlyViewedProd.URL_NAME + '/'}
                                                    className="review-count review-count-top">
                                                    <div className="GoToReviews">
                                                        <div className="review__stars stars stars--this.starSize stars--small">
                                                            <div className="stars__fill" style={{ width: (recentlyViewedProd.AVERAGE_REVIEW_SCORE * 20 + '%')}}></div>
                                                            <div className="stars__overlay">
                                                                <img src="imgs/ratingStars/star-small.png" />
                                                                <img src="imgs/ratingStars/star-small.png" />
                                                                <img src="imgs/ratingStars/star-small.png" />
                                                                <img src="imgs/ratingStars/star-small.png" />
                                                                <img src="imgs/ratingStars/star-small.png" />
                                                            </div>
                                                        </div>
                                                        <span className="review-count__number">({recentlyViewedProd.TOTAL_REVIEWS})</span>
                                                    </div>
                                                </a>
                                            : null}
                                        </div>



                                        {/* IA-4048 -Begin Suppress Pack Price and Allow for Bundled Products ---------------------------------------------------------------------------*/}
                                        {(recentlyViewedProd.IsProductOnSale || recentlyViewedProd.ValuePackBundleType != ValuePackBundleType.None ) &&

                                            <div className="product-card__price price-block">

                                                <div className="price price--strikethrough"> 
                                                    {recentlyViewedProd.MSRP_UIFormatted}
                                                </div>

                                                {(recentlyViewedProd.IsProductOnSale && recentlyViewedProd.SavingsPercentage > 0) &&
                                                    <div className="price price--strong">
                                                        {recentlyViewedProd.RETAIL_PRICE_UIFormatted}
                                                    </div>
                                                }

                                                {((recentlyViewedProd.SuppressPackPrice && recentlyViewedProd.ValuePackBundleType == ValuePackBundleType.ValuePack)
                                                    || recentlyViewedProd.ValuePackBundleType == ValuePackBundleType.Bundle) &&
                                                    <div className="price price--strong">
                                                        {recentlyViewedProd.RETAIL_PRICE_UIFormatted}
                                                    </div>
                                                }

                                                {(!recentlyViewedProd.SuppressPackPrice && recentlyViewedProd.ValuePackBundleType == ValuePackBundleType.ValuePack) &&
                                                    <div className="price price--strikethrough">
                                                        {recentlyViewedProd.FullPrice_UIFormatted}
                                                    </div>
                                                }

                                                {(!recentlyViewedProd.SuppressPackPrice && recentlyViewedProd.ValuePackBundleType == ValuePackBundleType.ValuePack) &&
                                                    <div className="price price--strong">
                                                        {(window as any).packPrice} <span className="nowrap">{recentlyViewedProd.RETAIL_PRICE_UIFormatted}</span>
                                                    </div>
                                                }

                                            </div>
                                        }


                                        {(!recentlyViewedProd.IsProductOnSale && recentlyViewedProd.ValuePackBundleType == ValuePackBundleType.None) &&
                                            <div className="product-card__price price-block">
                                                <div className="price price--strikethrough">
                                                    {recentlyViewedProd.MSRP_UIFormatted}
                                                </div>

                                                <div className="price price--strong">
                                                    {recentlyViewedProd.RETAIL_PRICE_UIFormatted}
                                                </div>
                                            </div>
                                        }
                                        {/*// IA-4048 - End Suppress Pack Price and Allow for Bundled Products ---------------------------------------------------------------------------*/}


                                        {(!recentlyViewedProd.OutOfStock && !recentlyViewedProd.RemoveCartButton) &&
                                            <a className="product-card__button button button--green button--small"
                                                aria-label="Add to Cart"
                                                onClick={() => this.onClickAddToCart(recentlyViewedProd.PRODUCT_ID)}>
                                                {(window as any).addToCart} &raquo;
                                            </a>}
                                        {(recentlyViewedProd.OutOfStock) &&
                                            <div className="out-of-stock">
                                                <div className="out-of-stock__icon">
                                                    <img src="webcontent/icons/baseline-error_outline-24px--cc4b37.svg" />
                                                </div>
                                                <div className="out-of-stock__message">
                                                    <p>
                                                        {(recentlyViewedProd.OutOfStockModel != null) &&
                                                            recentlyViewedProd.OutOfStockModel.OutOfStockMsg}
                                                        {(recentlyViewedProd.OutOfStock == null) &&
                                                            (window as any).stringResourcesRecentlyViewed.outOfStock}
                                                    </p>
                                                    <ul>
                                                        {(recentlyViewedProd.AlternateProduct != null) &&
                                                            <li>
                                                                <a href={'product/detail/' + recentlyViewedProd.AlternateProduct.PRODUCT_ID + '/' + recentlyViewedProd.AlternateProduct.URL_NAME}>
                                                                    {(window as any).stringResourcesRecentlyViewed.recommendedAlternative}
                                                                </a>
                                                            </li>}
                                                        <li>
                                                            <a onClick={() => this.NotfyWhenProdInStck_CLICK(recentlyViewedProd.PRODUCT_ID)}
                                                                href="javascript:void(0)">
                                                                {(window as any).stringResourcesRecentlyViewed.notifyWhenInStock}
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>}
                                        </div>
                                </div>
                            </div>
                        )}
                           </div>
                           <div className="product-slider__loader">
                               <div className="lds-ring"><div></div><div></div><div></div><div></div></div>
                           </div>
                       </div>
                   </div>
               </div>;

    }

    componentDidMount() {
        notifyWindowScriptLoaded();
        return fetch(GetBaseUrl() + 'webservicesCORE1/product/GetSetRecentlyViewed', {
            method: "GET", // *GET, POST, PUT, DELETE, etc.
            mode: "cors", // no-cors, cors, *same-origin
            cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
            credentials: "same-origin", // include, *same-origin, omit
            headers: {
                "Content-Type": "application/json",
                // "Content-Type": "application/x-www-form-urlencoded",
            }
        })
            .then((response) => response.json())
            .then((responseJson) => {
                if (responseJson !== undefined && responseJson !== null && responseJson.length >0) {
                    this.setState({
                            isLoading: false,
                            recentlyViewed: responseJson,
                        },
                        function () {

                        })
                    initSlider();
                }





            })
            .catch((error) => {
                console.error(error);
            });


    }

    static NotfyWhenProdInStck_CLICK(pid) {
        (window as any).$ClientMiddleware.NotifyInStock(pid);
    }

    //static onClickAddToCart(arg0: any): any {
    //    throw new Error("Method not implemented.");
    //}

    static onClickAddToCart(pid: number) {

        if ((window as any).$ClientMiddleware) {
            (window as any).$ClientMiddleware.AddPidToCartPromise(pid);
        } else {
            (window as any).angularHeaderAddPidToCart.zone.run(function () {
                (window as any).angularHeaderAddPidToCart.componentFn(pid);
            });
        }

    }
}

var _baseURL = null;
function GetBaseUrl() {

    if (_baseURL == null) {
        _baseURL = document.getElementsByTagName('base')[0].href;
    }
    return _baseURL;
}

function initSlider() {
    (window as any).productSlider.init();
}

function notifyWindowScriptLoaded() {
    (window as any).recentlyViewedScript_IsLoading = true;
}
